import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_modal = _resolveComponent("ion-modal")!

  return (_openBlock(), _createBlock(_component_ion_modal, { ref: "accountModal" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cancel()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Cerrar")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, { class: "ion-text-center" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.service.name), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_button, {
                    strong: true,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.confirm()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Guardar")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { class: "ion-padding" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, { collapse: "condense" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_title, { size: "large" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.service.name), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { position: "floating" }, {
                default: _withCtx(() => [
                  _createTextVNode("Nombre")
                ]),
                _: 1
              }),
              _createVNode(_component_ion_input, {
                modelValue: _ctx.service.name,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.service.name) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { position: "floating" }, {
                default: _withCtx(() => [
                  _createTextVNode("Email")
                ]),
                _: 1
              }),
              _createVNode(_component_ion_input, {
                modelValue: _ctx.service.email,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.service.email) = $event)),
                type: "email"
              }, null, 8, ["modelValue"]),
              _createVNode(_component_ion_button, {
                slot: "end",
                fill: "clear",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.copyEmail()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { name: "copy" })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { position: "floating" }, {
                default: _withCtx(() => [
                  _createTextVNode("Contraseña")
                ]),
                _: 1
              }),
              _createVNode(_component_ion_input, {
                modelValue: _ctx.service.password,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.service.password) = $event)),
                type: _ctx.showPassword ? 'text' : 'password'
              }, null, 8, ["modelValue", "type"]),
              _createVNode(_component_ion_icon, {
                slot: "end",
                name: _ctx.showPassword ? 'eye-off' : 'eye',
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.toggleShowPassword()))
              }, null, 8, ["name"]),
              _createVNode(_component_ion_button, {
                slot: "end",
                fill: "clear",
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.copyPassword()))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { name: "copy" })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { position: "floating" }, {
                default: _withCtx(() => [
                  _createTextVNode("Fecha de vencimiento")
                ]),
                _: 1
              }),
              _createVNode(_component_ion_input, {
                modelValue: _ctx.service.expiration,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.service.expiration) = $event)),
                type: "date"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, { position: "floating" }, {
                default: _withCtx(() => [
                  _createTextVNode("Precio")
                ]),
                _: 1
              }),
              _createVNode(_component_ion_input, {
                modelValue: _ctx.service.price,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.service.price) = $event))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 512))
}