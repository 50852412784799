
import {
   IonButtons,
   IonButton,
   IonModal,
   IonHeader,
   IonContent,
   IonToolbar,
   IonTitle,
   IonItem,
   IonInput,
   IonLabel,
   IonIcon,
   toastController
} from '@ionic/vue';
import { defineComponent } from 'vue';
import { Service } from '@/data/services';
import { addIcons } from 'ionicons';
import { eye, eyeOff, copy } from 'ionicons/icons';

addIcons({
   'eye': eye,
   'eye-off': eyeOff,
   'copy': copy
});

export default defineComponent({
   name: 'AccountItemModal',
   components: {
      IonButtons,
      IonButton,
      IonModal,
      IonHeader,
      IonContent,
      IonToolbar,
      IonTitle,
      IonItem,
      IonInput,
      IonLabel,
      IonIcon
   },
   emits: ['update'],
   data() {
      return {
         service: {} as Service,
         showPassword: false,
      };
   },
   methods: {
      confirm() {
         this.$emit('update', this.service);
         (this.$refs.accountModal as any).$el.dismiss(null, 'cancel');
      },
      cancel() {
         (this.$refs.accountModal as any).$el.dismiss(null, 'cancel');
      },
      present(service: Service) {
         this.service = service;
         (this.$refs.accountModal as any).$el.present();
      },
      toggleShowPassword() {
         this.showPassword = !this.showPassword;
      },
      copyPassword() {
         navigator.clipboard.writeText(this.service.password);
         toastController.create({
            message: 'Contraseña copiada',
            duration: 2000
         }).then(toast => toast.present());
      },
      copyEmail() {
         navigator.clipboard.writeText(this.service.email);
         toastController.create({
            message: 'Email copiado',
            duration: 2000
         }).then(toast => toast.present());
      }
   }
});
