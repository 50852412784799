export interface Service {
  id: number;
  name: string;
  date: string;
  expiration?: string;
  email: string;
  password: string;
  price: number;
  img?: string;
  id_service?: number;
}

let services: Service[] = [];

// get data from web service using fetch, send apikey in header
export const getServices = async () => {
  const URL_API = process.env.VUE_APP_URL_API;
  const response = await fetch(`${URL_API}/services`, {
    headers: {
      ApiKey: localStorage.getItem('token') || '',
    },
  });
  const data = await response.json();
  services = data.map((m: any) => ({
    id: m.NIDSERV,
    name: m.CNOMTIP,
    date: m.TFECHA,
    expiration: m.TEXPIRA,
    email: m.CEMAIL,
    password: m.CPASSWD,
    price: m.NPRICE,
    img: m.CIMAGE,
    id_service: m.NTIPSER,
  }));
  return services;
};

export const getService = (id: number) => services.find(m => m.id === id);

export const addService = async (service: Service) => {
  const URL_API = process.env.VUE_APP_URL_API;
  const response = await fetch(`${URL_API}/services`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ApiKey: localStorage.getItem('token') || '',
    },
    body: JSON.stringify(service),
  });
  const data = await response.json();
  services.push(data);
  return services;
}

export const updateService = async (service: Service) => {
  const URL_API = process.env.VUE_APP_URL_API;
  const response = await fetch(`${URL_API}/services/${service.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      ApiKey: localStorage.getItem('token') || '',
    },
    body: JSON.stringify(service),
  });
  const data = await response.json();
  if (data.ERROR) {
    return null;
  }
  return services;
}